<template>
    <v-card flat tile style="height: 94vh;">
        <v-card-title dark class="secondary white--text text-h5">
            Ajouter un vin et son code QR
            <v-btn class="mx-2" dark color="indigo" @click="ajoutervin">
                Enregistrer
            </v-btn>
            <v-spacer />
            <v-btn class="mx-2" dark color="indigo" to="/listevins">
                <v-icon dark>
                    mdi-arrow-left
                </v-icon>
                <div>Retour</div>
            </v-btn>
        </v-card-title>
        <v-snackbar v-model="showResult" :timeout="30000" top :color="color" tile>
            {{ result }}
        </v-snackbar>
        <v-card-text style="overflow-y: auto; max-height: calc(100% - 48px);">
            <v-container>
                <v-form v-model="valid" name="formvin">
                    <v-row>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo du vin</span>
                                <v-img contain :src="photovin" max-width="256" class="align-self-center" />
                            </div>
                            <v-file-input accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                label="Charger la photo du vin" @change="photovinchange" />
                        </v-col>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo de l'étiquette</span>
                                <v-img contain :src="photoetiquetteface" max-width="256" class="align-self-center" />
                            </div>
                            <v-file-input accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                label="Charger la photo de l'étiquette" @change="photoetiquettefacechange" />
                        </v-col>
                        <v-col>
                            <div class="d-flex flex-column align-center">
                                <span class="v-label pa-4">Photo de la contre étiquette</span>
                                <v-img contain :src="photoetiquettedos" max-width="256" class="align-self-center" />
                            </div>
                            <v-file-input accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                                label="Charger la photo de la contre-étiquette" @change="photoetiquettedoschange" />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="nomduvin" outlined label="Nom du vin" required />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="numerolot" outlined label="Numéro de lot" required />
                        </v-col>
                        <v-col>
                            <v-autocomplete v-model="millesime" outlined :items="arrmillesime" label="Millésime" required
                                clearable />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-combobox v-model="appellation" outlined :items="arrappellation"
                                :item-text="item => `${item.Appellation}`" :item-value="item => `${item.UUID}`"
                                label="Appellation" required :filter="customFilter" clearable persistent-hint small-chips
                                deletable-chips />
                        </v-col>
                        <v-col>
                            <v-select v-model="categorie" outlined :items="arrcategorie" label="Catégorie de vin" required
                                clearable />
                        </v-col>
                        <v-col>
                            <v-select v-model="couleur" outlined :items="arrcouleurs" label="Couleur" required clearable />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="conditionnement" outlined :items="arrconditionnement"
                                :item-text="item => `${item.nom}`" :item-value="item => `${item.UUID}`"
                                label="Conditionnement" required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-text-field v-model="tauxdalcool" outlined type="number" step="any" label="TAVA
                                (Titre alcoométrique volumique acquis)">
                                <template v-slot:append>
                                    % vol.
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-combobox v-model="cepage" outlined :items="arrcepage" :item-text="item => `${item.Cepage}`"
                                :item-value="item => `${item.UUID}`" label="Cépages" :filter="customFilterCepages" clearable
                                persistent-hint small-chips deletable-chips multiple />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h1>Déclaration nutritionnelle</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="valenerkj" label="Valeur énergétique Valeurs pour 100 ml" outlined
                                type="number" required>
                                <template v-slot:append>
                                    kJ
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="valenerkcal" label="Valeur énergétique Valeurs pour 100 ml" outlined
                                type="number" required>
                                <template v-slot:append>
                                    Kcal
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="glucides" outlined type="number" step="any" label="Glucides">
                                <template v-slot:append>
                                    g
                                </template>
                            </v-text-field>
                        </v-col>
                        <v-col>
                            <v-text-field v-model="sucreresiduel" outlined type="number" step="any"
                                label="dont sucre résiduel">
                                <template v-slot:append>
                                    g
                                </template>
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-checkbox v-model="checkboxfaiblequant"
                                label="Contient de faibles quantités de matières grasses, d'acides gras saturés, de protéines et de sel." />
                        </v-col>
                        <v-col>
                            <v-select v-model="ingredients" outlined :items="arringredients" label="les ingrédients"
                                required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Concerne les vins effervescents :
                                L'adjonction de liqueur de tirage (produit destiné à être ajouté à la cuvée pour provoquer
                                la prise de mousse) et de liqueur d’expédition (produit destiné à être ajouté aux vins
                                mousseux afin de leur conférer des caractéristiques gustatives particulières) peut être
                                indiquée par les mentions spécifiques "liqueur de tirage" et "liqueur d’expédition", seules
                                ou accompagnées d’une liste de leurs composants.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="liqueurtirage" outlined :items="arrliqueurtirage" label="Liqueur de tirage"
                                required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="liqueurexpedition" outlined :items="arrliqueurexpedition"
                                label="Liqueur d'expédition" required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Les additifs de la catégorie “régulateurs d’acidité”, similaires ou substituables entre eux,
                                peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum,
                                dont au moins un est présent dans le produit
                                final.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="regulateursacidite" outlined :items="arrregulateursacidite"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Régulateurs d'acidité" required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="conservateuretantioxydants" outlined :items="arrconservateuretantioxydants"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Conservateurs et antioxydants" required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="sequestrants" outlined :items="arrsequestrants" :item-text="formatItemText"
                                :item-value="item => `${item.nom}`" label="Séquestrants" required multiple chips
                                persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="activateursfermentation" outlined :items="arractivateursfermentation"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Activateurs de fermentation alcoolique et malolactique" required multiple chips
                                persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="agentsclarifiants" outlined :items="arragentsclarifiants"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`" label="Agents clarifiants"
                                required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Les additifs de la catégorie "agents stabilisateurs", similaires ou substituables entre eux,
                                peuvent être indiqués dans la liste des ingrédients suivie de trois additifs au maximum,
                                dont au moins un est présent dans le produit final.
                            </div>
                        </v-col>
                        <v-col>
                            <v-select v-model="agentsstabilisateurs" outlined :items="arragentsstabilisateurs"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Agents stabilisateurs" required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="enzymes" outlined :items="arrenzymes" :item-text="formatItemText"
                                :item-value="item => `${item.nom}`" label="Enzymes" required multiple chips
                                persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div style="text-align: justify;" class="v-label">
                                Gaz et gaz d'emballage, utiliser la mention « Mis en bouteille sous atmosphère protectrice"
                                ou "Peut être mis en bouteille sous atmosphère protectrice"
                            </div>
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="checkboxatmoprotect"
                                label="Mis en bouteille sous atmosphère protectrice" />
                        </v-col>
                        <v-col>
                            <v-checkbox v-model="checkboxmaybeatmoprotect"
                                label="Peut être mis en bouteille sous atmosphère protectrice" />
                        </v-col>
                        <v-col>
                            <v-select v-model="gazetgazemballage" outlined :items="arrgazetgazemballage"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Gaz et gaz d'emballage" required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select v-model="agentsfermentation" outlined :items="arragentsfermentation"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Agents de fermentation" required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="correctiondefauts" outlined :items="arrcorrectiondefauts"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`"
                                label="Correction des défauts" required multiple chips persistent-hint />
                        </v-col>
                        <v-col>
                            <v-select v-model="autrespratiques" outlined :items="arrautrespratiques"
                                :item-text="formatItemText" :item-value="item => `${item.nom}`" label="Autres pratiques "
                                required multiple chips persistent-hint />
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
import eventBus from '@/eventBus';
import diacritics from 'diacritics';

export default {
    name: 'Articlecreer',
    data: () => ({
        key: '',
        value: '',
        valid: false,
        showResult: false,
        color: '',
        result: '',
        imgdisplay: null,
        height: 300,
        arrmillesime: [],
        arrappellation: [],
        arrappellationCopy: [],
        arrcepage: [],
        arringredients: ['Raisins', 'Moût de raisins', 'Sucre (saccharose)', 'Moût de raisins concentré', 'Liqueur de tirage', 'Liqueur d\'expédition'],
        arrliqueurtirage: [],
        arrliqueurexpedition: [],
        arrregulateursacidite: [],
        arrconservateuretantioxydants: [],
        arrsequestrants: [],
        arractivateursfermentation: [],
        arragentsclarifiants: [],
        arragentsstabilisateurs: [],
        arrenzymes: [],
        arrgazetgazemballage: [],
        arragentsfermentation: [],
        arrcorrectiondefauts: [],
        arrautrespratiques: [],
        arrcepageCopy: [],
        arrcouleurs: [],
        arrcouleursCopy: [],
        arrconditionnement: [],
        arrcategorie: ['VSIG (vin sans indication géographique) ou VIN DE TABLE', 'IGP (vin avec indication géographique protégée) ou VIN DE PAYS', 'AOP (vin avec appellation d’origine protégée) ou AOC'],
        photovin: null,
        photovintoupload: null,
        photoetiquetteface: null,
        photoetiquettefacetoupload: null,
        photoetiquettedos: null,
        photoetiquettedostoupload: null,
        categorie: '',
        etiquetteface: '',
        etiquettedos: '',
        millesime: '',
        searchTerm: '',
        searchCepage: '',
        appellation: '',
        cepage: '',
        valenerkj: 0,
        valenerkcal: 0,
        glucides: 0,
        sucreresiduel: 0,
        checkboxfaiblequant: false,
        ingredients: '',
        liqueurtirage: '',
        liqueurexpedition: '',
        regulateursacidite: '',
        conservateuretantioxydants: '',
        sequestrants: '',
        activateursfermentation: '',
        agentsclarifiants: '',
        agentsstabilisateurs: '',
        enzymes: '',
        checkboxatmoprotect: '',
        checkboxmaybeatmoprotect: '',
        gazetgazemballage: '',
        agentsfermentation: '',
        correctiondefauts: '',
        autrespratiques: '',
        couleur: '',
        nomduvin: '',
        numerolot: '',
        commentaire: '',
        conditionnement: ['44A6359B955AA44BAEBA63F95155A0B5'],
        tauxdalcool: '',
        image: null,
        imagetoupload: null,
    }),
    async mounted() {
        await this.getappellations();
        await this.getcepages();
        await this.getcouleur();
        await this.getconditionnement();
        await this.createyear();
        await this.getenumerations();
    },
    methods: {
        formatItemText(item) {
            let result = `${item.nom}`;
            if (item.code !== undefined) {
                result += ` ${item.code}`;
            }
            if (item.allergene !== undefined) {
                result += ` (${item.allergene.toUpperCase()})`;
            }
            return result;
        },
        createyear() {
            this.arryeartmp = Array.from({ length: 201 }, (v, i) => (i + (new Date().getFullYear() - 200)).toString());
            this.arrmillesime = this.arryeartmp.reverse();
            this.arrmillesime.unshift('Brut Sans Année');
            this.arrmillesime.unshift('Non millésimé');
        },
        updateDataTableHeight() {
            this.dataTableHeight = this.$refs.parentDiv.clientHeight;
        },
        async getappellations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallappellations`;
            await vm.$axios.get(url).then((response) => {
                this.arrappellation = response.data;
                this.arrappellationCopy = [...this.arrappellation];
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcepages() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getallcepages`;

            await vm.$axios.get(url).then((response) => {
                this.arrcepage = response.data;
                this.arrcepageCopy = [...this.arrcepage];
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        async getcouleur() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getcouleur`;
            const response = await vm.$axios.get(url);
            vm.arrcouleurs = response.data.couleur;
            vm.arrcouleursCopy = JSON.parse(JSON.stringify(vm.arrcouleurs));
        },
        async getconditionnement() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getconditionnement`;
            const response = await vm.$axios.get(url);
            vm.arrconditionnement = response.data;
        },
        async getenumerations() {
            const vm = this;
            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_getenumerations`;

            let resultats = '';
            const response = await vm.$axios.get(url);
            vm.arrenumerations = response.data;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurtirage');
            vm.arrliqueurtirage = resultats[0].liste.liqueurtirage;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'liqueurexpedition');
            vm.arrliqueurexpedition = resultats[0].liste.liqueurexpedition;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'regulateursacidite');
            vm.arrregulateursacidite = resultats[0].liste.regulateursacidite;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'conservateuretantioxydants');
            vm.arrconservateuretantioxydants = resultats[0].liste.conservateuretantioxydants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'sequestrants');
            vm.arrsequestrants = resultats[0].liste.sequestrants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'activateursfermentationalcooliquemalolactique');
            vm.arractivateursfermentation = resultats[0].liste.activateursfermentationalcooliquemalolactique;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsclarifiants');
            vm.arragentsclarifiants = resultats[0].liste.agentsclarifiants;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsstabilisateurs');
            vm.arragentsstabilisateurs = resultats[0].liste.agentsstabilisateurs;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'enzymes');
            vm.arrenzymes = resultats[0].liste.enzymes;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'gazetgazemballage');
            vm.arrgazetgazemballage = resultats[0].liste.gazetgazemballage;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'agentsfermentation');
            vm.arragentsfermentation = resultats[0].liste.agentsfermentation;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'correctiondefauts');
            vm.arrcorrectiondefauts = resultats[0].liste.correctiondefauts;

            resultats = vm.arrenumerations.filter((word) => word.nom === 'autrespratiques');
            vm.arrautrespratiques = resultats[0].liste.autrespratiques;
        },
        customFilter(item, queryText) {
            const textOne = diacritics.remove(item.Appellation.toLowerCase()).replace(/-/g, ' ');
            const searchText = diacritics.remove(queryText.toLowerCase()).replace(/-/g, ' ');
            return textOne.indexOf(searchText) > -1;
        },
        customFilterCepages(item, queryText) {
            const textOne = diacritics.remove(item.Cepage.toLowerCase()).replace(/-/g, ' ');
            const searchText = diacritics.remove(queryText.toLowerCase()).replace(/-/g, ' ');
            return textOne.indexOf(searchText) > -1;
        },
        searchcepageinput() {
            if (!this.searchCepage) {
                this.arrcepage = this.arrcepageCopy;
            }

            this.arrcepage = this.arrcepageCopy.filter(
                (cepage) => {
                    const rep = cepage.Cepage.toLowerCase().indexOf(this.searchCepage.toLowerCase()) > -1;
                    return rep;
                }
            );
        },
        ajoutervin() {
            console.log('ajout vin en cours');
            const vm = this;
            if (!this.nomduvin) {
                vm.color = 'red';
                vm.result = 'Veuillez renseigner le nom du vin';
                vm.showResult = true;
                return;
            }

            const bodyFormData = new FormData();
            bodyFormData.append('uuiduser', this.$store.getters.token);
            bodyFormData.append('nomduvin', this.nomduvin);
            bodyFormData.append('numerolot', this.numerolot);
            bodyFormData.append('millesime', this.millesime);
            if (typeof this.appellation === 'object') {
                bodyFormData.append('appellation', this.appellation.UUID);
            }
            bodyFormData.append('couleur', this.couleur);
            bodyFormData.append('conditionnement', this.conditionnement);
            bodyFormData.append('tauxdalcool', this.tauxdalcool);
            bodyFormData.append('categorie', this.categorie);
            bodyFormData.append('cepage', JSON.stringify(this.cepage));
            bodyFormData.append('valenerkj', this.valenerkj);
            bodyFormData.append('valenerkcal', this.valenerkcal);
            bodyFormData.append('glucides', this.glucides);
            bodyFormData.append('sucreresiduel', this.sucreresiduel);
            bodyFormData.append('checkboxfaiblequant', this.checkboxfaiblequant);
            bodyFormData.append('ingredients', this.ingredients);
            bodyFormData.append('liqueurtirage', this.liqueurtirage);
            bodyFormData.append('liqueurexpedition', this.liqueurexpedition);
            bodyFormData.append('regulateursacidite', this.regulateursacidite);
            bodyFormData.append('conservateuretantioxydants', this.conservateuretantioxydants);
            bodyFormData.append('sequestrants', this.sequestrants);
            bodyFormData.append('activateursfermentation', this.activateursfermentation);
            bodyFormData.append('agentsclarifiants', this.agentsclarifiants);
            bodyFormData.append('agentsstabilisateurs', this.agentsstabilisateurs);
            bodyFormData.append('enzymes', this.enzymes);
            bodyFormData.append('checkboxatmoprotect', this.checkboxatmoprotect);
            bodyFormData.append('checkboxmaybeatmoprotect', this.checkboxmaybeatmoprotect);
            bodyFormData.append('gazetgazemballage', this.gazetgazemballage);
            bodyFormData.append('agentsfermentation', this.agentsfermentation);
            bodyFormData.append('correctiondefauts', this.correctiondefauts);
            bodyFormData.append('autrespratiques', this.autrespratiques);

            if ((this.photovintoupload !== null) && (this.photovintoupload.length > 0)) {
                bodyFormData.append('photovin', vm.createblob(vm.photovintoupload));
            }

            if ((this.photoetiquettedostoupload !== null) && (this.photoetiquettedostoupload.length > 0)) {
                bodyFormData.append('photoetiquettedos', vm.createblob(this.photoetiquettedostoupload));
            }

            if ((this.photoetiquettefacetoupload !== null) && (this.photoetiquettefacetoupload.length > 0)) {
                bodyFormData.append('photoetiquetteface', vm.createblob(this.photoetiquettefacetoupload));
            }

            const url = `${vm.$config.API_ENDPOINT}/4DACTION/ksf_web_ajoutVin`;
            vm.$axios.post(url, bodyFormData).then((response) => {
                const result = response.data;
                if (result.header === 'Succès') {
                    eventBus.$emit('refreshlistvins');
                    vm.result = 'Le vin a bien été ajouté';
                    vm.color = 'green';
                    vm.showResult = true;
                    vm.succes = true;
                    console.log('succes');
                    vm.$router.push('/listevins');
                } else {
                    vm.error = true;
                    vm.result = 'Certains champs ne peut pas etre vide.';
                    vm.showResult = true;
                    vm.succes = false;
                }
                return vm.succes;
            }).catch((error) => {
                this.errorMessage = error.message;
                console.error('There was an error!', error);
            });
        },
        photovinchange(fileObject) {
            const vm = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                vm.photovintoupload = e.target.result;
                vm.photovin = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        photoetiquettefacechange(fileObject) {
            const vm = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                vm.photoetiquettefacetoupload = e.target.result;
                vm.photoetiquetteface = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        photoetiquettedoschange(fileObject) {
            const vm = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                vm.photoetiquettedostoupload = e.target.result;
                vm.photoetiquettedos = e.target.result;
            };
            reader.readAsDataURL(fileObject);
        },
        createblob(srcoffile) {
            let encoded = srcoffile.replace(/^data:(.*;base64,)?/, '');
            if ((encoded.length % 4) > 0) {
                encoded += '='.repeat(4 - (encoded.length % 4));
            }
            return encoded;
        }
    },
};
</script>
